/* You can add global styles to this file, and also import other style files */
.download-btn {
  display: inline-block;
  box-sizing: border-box;
  margin-left: 1rem;
  margin-bottom: 0.333em;
  padding: 0.5em 1em;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  line-height: 1.6em;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

._ngcontent-sru-c128 {
  display: none !important;
}

.page-title {
  position: absolute;
  left: 30%;
  right: 40%;
  top: 2.5%;
  width: 40%;
}

.search-box {
  padding: 4px;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: inherit;
  float: right;
}

#DataTables_Table_2_paginate {
  display: none;
}

.dataTables_filter {
  display: none;
}

.dataTables_paginate.paging_full_numbers,
.paging_simple_numbers,
.dataTables_info {
  display: none;
}

.ngx-pagination {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.ngx-pagination li {
  margin: 0 25px;
}

.print-headers th {
  cursor: pointer;
  min-width: 120px;
}

.fa-sort {
  color: rgb(83, 131, 131);
  float: right;
  /* position: absolute; */
}

td {
  background: #fff !important;
}
